




























































import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { WorkOrderEarlyWarning } from '@/shared/models/wo.model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import store, { DivisionModule } from '@/store/index';
import { EarlyWarningService } from '@/shared/services/early-warning/early-warning.service';

@Component({
  components: { WoBaseModal }
})
export default class EarlyWarningModal extends Vue {
  woModalId = 'wo-base-modal-early-warning';

  selectedWo = null;
  tabIndex = 0;

  workOrders: WorkOrderEarlyWarning[] = [];

  created() {
    this.loadData();

    store.watch(
      (state: any) => state.division.division,
      () => this.loadData()
    );
  }

  async loadData() {
    const { data } = await EarlyWarningService.getActiveWarnings(
      DivisionModule.division
    );
    this.workOrders = data.data;
  }

  openWO(wo: WorkOrderEarlyWarning) {
    this.selectedWo = wo;
    this.$bvModal.show(this.woModalId);
  }

  async close(id: string) {
    await EarlyWarningService.close(id);
  }
}
